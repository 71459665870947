import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ModalComponent, ModalTypes } from './modal.types';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private subscriptions$: Subscription[] = [];

	dialog = inject(MatDialog);
	dialogRef?: MatDialogRef<unknown>;

	components: ModalComponent = {
		'add-policy': import(`./add-policy/add-policy.component`).then(
			({ AddPolicyComponent }) => AddPolicyComponent,
		),
		'copy-device': import(`./copy-device/copy-device.component`).then(
			({ CopyDeviceComponent }) => CopyDeviceComponent,
		),
		'delete-device': import(`./delete-device/delete-device.component`).then(
			({ DeleteDeviceComponent }) => DeleteDeviceComponent,
		),
		'delete-policy': import(`./delete-policy/delete-policy.component`).then(
			({ DeletePolicyComponent }) => DeletePolicyComponent,
		),
		'device-timer': import(`./device-timer/device-timer.component`).then(
			({ DeviceTimerComponent }) => DeviceTimerComponent,
		),
		'import-device': import(`./import-device/import-device.component`).then(
			({ ImportDeviceComponent }) => ImportDeviceComponent,
		),
		'session-confirm': import(`./session-confirm/session-confirm.component`).then(
			({ SessionConfirmComponent }) => SessionConfirmComponent,
		),
	};

	constructor() {}

	async getComponent(component: ModalTypes) {
		return await this.components[component];
	}

	async open(type: ModalTypes, data?: unknown, config?: MatDialogConfig) {
		const component = await this.getComponent(type);

		this.close();
		this.dialogRef = this.dialog.open(component, {
			closeOnNavigation: true,
			disableClose: type === 'session-confirm',
			autoFocus: 'dialog',
			...(config || {}),
			data,
		});
	}

	afterClose(callback?: (value: any) => void) {
		const afterCloseSubscription$ = this.dialogRef?.afterClosed().subscribe(callback);
		this.subscriptions$.push(afterCloseSubscription$ as Subscription);
	}

	close() {
		this.dialog.closeAll();
		this.dialogRef = undefined;

		this.subscriptions$.forEach((subscription) => subscription?.unsubscribe());
	}
}
